<template>
<div class="notice">
  <div class="head">
    <van-image :src="headImg" :error-icon="sex === 1? man : woman"></van-image>
    <p>{{name}}</p>
    <div class="introduce">
      <h3>代表风采</h3>
      <div class="content" v-html="content">
      </div>
      <div class="addBtn" @click="goAdd">
        <img src="@/assets/img/weAdd.png" alt="">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {getImageStream} from "../../utils";
export default {
  components :{
    topBar,
    getImageStream
  },
  name: "peopleMail",
  data() {
    return {
      page: 0,
      isLoading:false,
      loading: false,
      finished:false,
      id: 0,
      sex: 1,
      content: '',
      name: '',
      headImg: '',
      man: require('@/assets/img/weMan.png'),
      woman: require('@/assets/img/weWoman.png'),
      dataList:[]
    };
  },
  created() {
    this.id = this.$route.query.id
    this.getInfo()
    this.getPeopleInfo()
  },
  methods: {
    goAdd () {
      this.$router.push({path: '/peopleMail-info', query: {userId: this.id, name: this.name}})
    },
    getPeopleInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/simple/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.headImg = getImageStream(data.userInfo.headImg || 'aaa')
          this.name = data.userInfo.name
          this.sex = data.userInfo.sex
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    getInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/app/userinfoauxiliary/getIntroduce'),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.id
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.content = data.introduce
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
  }
}
</script>

<style scoped lang="scss" >
.notice {
  padding-top: 100px;
  min-height: 100vh;
  background-color: #FFFFFF !important;
  .head {
    position: relative;
    height: 294px;
    background-image: url("../../assets/img/weDeputy.png");
    background-size: 100%;
    background-repeat: no-repeat;
    ::v-deep .van-image {
      position: absolute;
      top:40%;
      left:50%;
      transform:translate(-50%,-50%);
      display: block;
      width: 104px;
      height: 104px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    ::v-deep .van-image__error {
      border-radius: 50%;
    }
    p {
      position: absolute;
      top:65%;
      left:50%;
      transform:translate(-50%,-50%);
      font-size: 30px;
      font-weight: 600;
      font-family: PingFang SC;
      color: #FFFFFF;
    }
    .introduce {
      position: absolute;
      top: 90%;
      left: 0;
      width: 100%;
      border-radius: 40px;
      background-color: #FFFFFF;
      h3 {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-top: 50px;
        margin-left: 30px;
      }
      .content {
        display: block;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4A505A;
        line-height: 60px;
        margin: 0 30px;
      }
    }
  }
  .cont {
    position: absolute;
    top: 45%;
    left: 0;
    border-radius: 20px;
    background-color: #FFFFFF !important;
    h3 {
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .van-cell {
      border: 1px solid #CFCFCF;
      padding: 25px 30px;
    }
    .item {
      .van-row:nth-child(1) .van-col:nth-child(2){
        margin-top: 10px;
        padding-left: 22px;
      }
      .van-row:nth-child(2) .van-col:nth-child(2){
        padding-left: 22px;
      }
      p {
        font-size: 30px;
        color: #272727;
        margin: 10px 0;
      }
      span {
        font-size: 26px;
      }
    }
  }
}
.addBtn {
  position: fixed;
  right: 40px;
  bottom: 130px;
  z-index: 999;
  width: 160px;
  height: 160px;
  img {
    width: 100%;
  }
}
</style>
